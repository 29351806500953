import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Resolver, useForm } from 'react-hook-form'

import { ContentDisplay } from '../../common/components'
import DataCollectionRuleForm from '../../common/components/DataCollectionForms/DataCollectionRuleForm'
import { manualRuleForm } from '../../common/components/DataCollectionForms/formSchemas'
import { Button, InputField } from '../../common/designs'
import type { DataCollectionRuleFormData } from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'

interface Props {
  appBaseRule: DataCollectionRuleFormData
  isSystemDiscoveryApp: boolean
  onCreateDraftRule: (formData: DataCollectionRuleFormData, windowOrAppName: string) => void
}

const TrainingDataCreateNewWindowOrAppForm = ({
  appBaseRule,
  isSystemDiscoveryApp,
  onCreateDraftRule,
}: Props) => {
  const defaultValues = appBaseRule ?? {
    inTitle: [{ value: '' }],
    inUrl: [{ value: '' }],
  }
  const { control: ruleFormControl, handleSubmit } = useForm<DataCollectionRuleFormData>({
    resolver: yupResolver(manualRuleForm) as Resolver<DataCollectionRuleFormData>,
    defaultValues,
  })

  const [windowOrAppName, setWindowOrAppname] = useState<string>('')
  const [windowNameError, setWindowNameError] = useState<string | undefined>(undefined)

  const inputLabel = isSystemDiscoveryApp ? 'Application Name' : 'Window Name'

  const onSaveRule = (formData: DataCollectionRuleFormData) => {
    if (!windowOrAppName || windowOrAppName.length === 0) {
      setWindowNameError(`${inputLabel} Required`)
      return
    }

    setWindowNameError(undefined)
    onCreateDraftRule(formData, windowOrAppName)
  }

  return (
    <ContentDisplay className='p-4'>
      <p>{`Add New ${isSystemDiscoveryApp ? 'Application' : 'Window'}`}</p>
      <form onSubmit={handleSubmit(onSaveRule)} className='space-y-4'>
        <InputField
          size='s'
          label={inputLabel}
          value={windowOrAppName}
          onChange={(e) => setWindowOrAppname(e.target.value)}
          error={windowNameError}
        />
        <DataCollectionRuleForm
          control={ruleFormControl}
          isForProcessConfigs={false}
          uiFieldNameInputs={{}}
        />
        <div className='space-x-2'>
          <Button size='s' type='submit'>
            Preview matched training data for the rule
          </Button>
        </div>
      </form>
    </ContentDisplay>
  )
}

export default TrainingDataCreateNewWindowOrAppForm
