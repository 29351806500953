import type {
  GroupedTrainingData,
  TrainingData,
  TrainingDataFormValues,
  TrainingDataHeaders,
  TrainingDataTableValues,
} from './types'

export const getTrainingDataTableValues = (
  formSelections: TrainingDataFormValues,
  trainingData: TrainingData[],
): TrainingDataTableValues => {
  const groupedData = groupTrainingData(formSelections, trainingData)
  const tableHeaders = getTableHeaderValues(formSelections)
  const tableRows = groupedData.map((dataRow) =>
    tableHeaders.map((header) => dataRow[header] ?? ''),
  )

  return {
    tableRows,
    tableHeaders: variableValuesToTableHeaders(tableHeaders),
  }
}

const groupTrainingData = (
  formSelections: TrainingDataFormValues,
  trainingData: TrainingData[],
): GroupedTrainingData[] => {
  const dataWithWantedFields = trainingData.map((dataRow) => {
    const outputData: GroupedTrainingData = {
      total_visit_count: dataRow.total_visit_count,
      groupByKey: null,
    }
    const groupByKeys: string[] = []

    // Handle process name.
    if (formSelections.isProcessNameValueUsed) {
      const activeProcessNameLower = dataRow.active_process_name_lower ?? ''
      outputData.active_process_name = activeProcessNameLower
      if (activeProcessNameLower.length > 0) {
        groupByKeys.push(activeProcessNameLower)
      }
    }

    // Handle URL field.
    if (formSelections.isUrlValueUsed) {
      const url = handleUrlParsing(
        dataRow.url,
        formSelections.removeUrlIdentifierAfterKeyWord,
        formSelections.cutUrlAfterKeyword,
      )
      outputData.url = url
      if (url && url.length > 0) groupByKeys.push(url)
    }

    // Handle title field.
    if (formSelections.isTitleValueUsed) {
      const title = handleTitleParsing(
        dataRow.title_lower,
        formSelections.removeTitleIdentifierAfterKeyWord,
        formSelections.cutTitleAfterKeyword,
      )
      outputData.title = title
      if (title && title.length > 0) groupByKeys.push(title)
    }

    outputData.groupByKey = groupByKeys.length > 0 ? groupByKeys.join(' | ') : null

    return outputData
  })

  return groupTrainingDataRows(dataWithWantedFields)
}

const handleTitleParsing = (
  title: string | null,
  removeTitleIdentifierAfterKeyWord: string,
  cutTitleAfterKeyword: string,
): string | null => {
  return handleFieldParsing(title, removeTitleIdentifierAfterKeyWord, ' ', cutTitleAfterKeyword)
}

const handleUrlParsing = (
  url: string | null,
  removeUrlIdentifierAfterKeyWord: string,
  cutUrlAfterKeyword: string,
) => {
  return handleFieldParsing(url, removeUrlIdentifierAfterKeyWord, '/', cutUrlAfterKeyword)
}

const handleFieldParsing = (
  fieldValue: string | null,
  removeIdentifierAfterKeyword: string,
  identifierEndingChar: string,
  cutAfterKeyword: string,
) => {
  if (!fieldValue) return fieldValue

  let parsedValue = fieldValue
  if (removeIdentifierAfterKeyword && parsedValue.includes(removeIdentifierAfterKeyword)) {
    const splittedByKeyWord = parsedValue.split(removeIdentifierAfterKeyword)
    const keyWordAdded = splittedByKeyWord.length > 1 ? removeIdentifierAfterKeyword : ''
    const endingSplitted =
      splittedByKeyWord.length > 1 ? splittedByKeyWord[1].split(identifierEndingChar) : ['']
    const addedEnding =
      endingSplitted.length > 1
        ? identifierEndingChar + endingSplitted.slice(1).join(identifierEndingChar)
        : ''
    parsedValue = splittedByKeyWord[0] + keyWordAdded + 'XXX' + addedEnding
  }
  if (cutAfterKeyword && parsedValue.includes(cutAfterKeyword)) {
    parsedValue = parsedValue.split(cutAfterKeyword)[0]
  }

  return parsedValue
}

const groupTrainingDataRows = (
  dataWithWantedFields: GroupedTrainingData[],
): GroupedTrainingData[] => {
  const groupMapper: { [key: string]: GroupedTrainingData } = {}

  dataWithWantedFields.forEach((dataRow) => {
    if (!dataRow.groupByKey) return

    if (dataRow.groupByKey in groupMapper) {
      groupMapper[dataRow.groupByKey].total_visit_count += dataRow.total_visit_count
    } else {
      groupMapper[dataRow.groupByKey] = dataRow
    }
  })

  const trainingDataArray = Object.values(groupMapper)
  trainingDataArray.sort((a, b) =>
    a.groupByKey && b.groupByKey ? a.groupByKey?.localeCompare(b.groupByKey) : 1,
  )
  return trainingDataArray
}

const getTableHeaderValues = (formSelections: TrainingDataFormValues): TrainingDataHeaders[] => {
  const tableHeaders: TrainingDataHeaders[] = []
  if (formSelections.isProcessNameValueUsed) {
    tableHeaders.push('active_process_name')
  }
  if (formSelections.isUrlValueUsed) {
    tableHeaders.push('url')
  }
  if (formSelections.isTitleValueUsed) {
    tableHeaders.push('title')
  }
  tableHeaders.push('total_visit_count')

  return tableHeaders
}

const variableValuesToTableHeaders = (tableHeaders: TrainingDataHeaders[]): string[] => {
  const headerMapper: { [key in TrainingDataHeaders]: string } = {
    active_process_name: 'Process Name',
    url: 'URL',
    title: 'Title',
    total_visit_count: 'Total Visits',
  }

  return tableHeaders.map((val) => headerMapper[val])
}

export const exportedForTesting = {
  groupTrainingData,
  getTableHeaderValues,
}
