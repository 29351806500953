import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, Resolver, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ContentDisplay } from '../../common/components'
import { Button, InputField, Toggle } from '../../common/designs'
import type { TrainingDataFormValues } from './types'

interface Props {
  onFormSend: React.Dispatch<React.SetStateAction<TrainingDataFormValues>>
  defaultValues: TrainingDataFormValues
}

const formSchema = yup
  .object({
    isProcessNameValueUsed: yup.boolean().required().default(true),
    isUrlValueUsed: yup.boolean().required().default(true),
    isTitleValueUsed: yup.boolean().required().default(true),

    removeUrlIdentifierAfterKeyWord: yup
      .string()
      .max(200, 'Cannot be more than 200 characters long'),
    cutUrlAfterKeyword: yup.string().max(200, 'Cannot be more than 200 characters long'),
    removeTitleIdentifierAfterKeyWord: yup
      .string()
      .max(200, 'Cannot be more than 200 characters long'),
    cutTitleAfterKeyword: yup.string().max(200, 'Cannot be more than 200 characters long'),
  })
  .required()

const TrainingDataForm = ({ onFormSend, defaultValues }: Props) => {
  const { control, handleSubmit } = useForm<TrainingDataFormValues>({
    resolver: yupResolver(formSchema) as Resolver<TrainingDataFormValues>,
    defaultValues,
  })

  const onSaveForm = (formData: TrainingDataFormValues) => {
    onFormSend({ ...formData })
  }

  return (
    <ContentDisplay className='mt-3 p-4'>
      <p>Filter Training Data</p>
      <form onSubmit={handleSubmit(onSaveForm)} className='space-y-2'>
        <div className='flex flex-wrap gap-3'>
          <Controller
            name='isProcessNameValueUsed'
            control={control}
            render={({ field }) => <Toggle size='xs' label='Show Process Names' {...field} />}
          />
          <Controller
            name='isUrlValueUsed'
            control={control}
            render={({ field }) => <Toggle size='xs' label='Show URLs' {...field} />}
          />
          <Controller
            name='isTitleValueUsed'
            control={control}
            render={({ field }) => <Toggle size='xs' label='Show Titles' {...field} />}
          />
        </div>

        <Controller
          name='removeUrlIdentifierAfterKeyWord'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              label='Remove IDs from URLs after keyword'
              placeholder='e.g. case/'
              error={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='cutUrlAfterKeyword'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              label='Cut the URLs starting from a keyword'
              placeholder='e.g. ?'
              error={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='removeTitleIdentifierAfterKeyWord'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              label='Remove IDs from titles after keyword'
              placeholder='e.g. case number '
              error={error?.message}
              {...field}
            />
          )}
        />

        <Controller
          name='cutTitleAfterKeyword'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              label='Cut the titles starting from a keyword'
              placeholder='e.g. |'
              error={error?.message}
              {...field}
            />
          )}
        />
        <Button size='s' type='submit'>
          Update Data
        </Button>
      </form>
    </ContentDisplay>
  )
}

export default TrainingDataForm
