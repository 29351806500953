import { useGetTrainingDataForMatchingCriteriaQuery } from '../../app/apiSlice'
import { InlineLoader } from '../../common/components'
import { Button, Modal, Table } from '../../common/designs'
import type {
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'

const TABLE_HEADERS = ['Process Name', 'URL', 'Title', 'Total Visit Count']

interface Props {
  isOpen: boolean
  ruleDraft: RuleIn | null
  appName: string
  onClose: () => void
  onSubmit: (testCase: DataCollectionTestCaseFormData | null) => void
}

const TrainingDataMatchedRowsModal = ({ isOpen, ruleDraft, appName, onClose, onSubmit }: Props) => {
  const { data, isFetching } = useGetTrainingDataForMatchingCriteriaQuery(
    {
      application_name: appName,
      body: ruleDraft ? [ruleDraft.matching_criteria] : [],
    },
    { skip: ruleDraft === null },
  )

  const onCreateRuleAndTestCase = () => {
    if (!data || data.length === 0) {
      onSubmit(null)
      return
    }
    const testCase = {
      processName: data[0].active_process_name,
      url: data[0].url ? data[0].url.replace('https://', '') : data[0].url,
      title: data[0].title,
    } as DataCollectionTestCaseFormData
    onSubmit(testCase)
    onClose()
  }

  return (
    <Modal
      label='Preview Matched Training Data for a New Rule'
      size='6xl'
      open={isOpen}
      onClose={onClose}
    >
      {isFetching ? (
        <InlineLoader />
      ) : (
        <div>
          <Button onClick={onCreateRuleAndTestCase}>Create Rule</Button>
          <Table
            label='Matched Data Rows'
            head={TABLE_HEADERS}
            body={(data ?? [])
              .slice(0, 100)
              .map((row) => [row.active_process_name, row.url, row.title, row.total_visit_count])}
          />
        </div>
      )}
    </Modal>
  )
}

export default TrainingDataMatchedRowsModal
