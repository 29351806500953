import { PlusIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'

import type {
  DataCollectionFromUIElementRuleFormData,
  DataCollectionRuleFormData,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { IconButton, InputField, Link, Text } from '../../designs'
import {
  IdentifierFromTitleHelpModal,
  IdentifierFromUrlPathHelpModal,
  IdentifierFromUrlQueryHelpModal,
} from './DataCollectionRuleFormHelpModals'

interface Props {
  control: Control<DataCollectionRuleFormData, any>
  isForProcessConfigs?: boolean | undefined
  uiFieldNameInputs: DataCollectionFromUIElementRuleFormData
  setUiFieldNameInputs?: React.Dispatch<
    React.SetStateAction<DataCollectionFromUIElementRuleFormData>
  >
}

const DataCollectionRuleForm = ({
  control,
  isForProcessConfigs = false,
  uiFieldNameInputs,
  setUiFieldNameInputs,
}: Props) => {
  const { fields: inUrlFields, append: appendInUrl } = useFieldArray<DataCollectionRuleFormData>({
    control,
    name: 'inUrl',
  })
  const { fields: inTitleFields, append: appendInTitle } = useFieldArray({
    control,
    name: 'inTitle',
  })

  const [isIdFromPathHelpOpen, setIsIdFromPathHelpOpen] = useState<boolean>(false)
  const [isIdFromQueryHelpOpen, setIsIdFromQueryHelpOpen] = useState<boolean>(false)
  const [isIdFromTitleHelpOpen, setIsIdFromTitleHelpOpen] = useState<boolean>(false)

  const onChangeUiFieldKeywordValue = (newValue: string, fieldName: string, index: number) => {
    if (!setUiFieldNameInputs) return
    const fieldValueArrayCopy = [...uiFieldNameInputs[fieldName]]
    fieldValueArrayCopy[index] = newValue
    setUiFieldNameInputs({ ...uiFieldNameInputs, [fieldName]: fieldValueArrayCopy })
  }

  const onAddUiFieldInput = (fieldName: string) => {
    if (!setUiFieldNameInputs) return
    setUiFieldNameInputs({
      ...uiFieldNameInputs,
      [fieldName]: [...uiFieldNameInputs[fieldName], ''],
    })
  }

  return (
    <div>
      <div className='space-y-3'>
        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Keyword(s) to look from the URL (E.g. sap.com in URL)
          </label>
          {inUrlFields.map((item, index) => (
            <Controller
              key={item.id}
              name={`inUrl.${index}.value`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputField
                  size='s'
                  autoComplete='off'
                  placeholder='e.g. sap.com'
                  error={error?.message}
                  {...field}
                />
              )}
            />
          ))}
          <IconButton
            className='mt-1'
            variant='white'
            icon={<PlusIcon />}
            onClick={() => appendInUrl({ value: '' })}
          />
        </div>

        <Controller
          name='inProcessName'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <InputField
              size='s'
              label='Keyword to look from the process name (E.g. excel.exe in process name)'
              autoComplete='off'
              placeholder='e.g. excel.exe'
              error={error?.message}
              {...field}
            />
          )}
        />

        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Keyword(s) to look from the window/tab title (E.g. SAP in title)
          </label>
          {inTitleFields.map((item, index) => (
            <Controller
              key={item.id}
              name={`inTitle.${index}.value`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputField
                  size='s'
                  label=''
                  autoComplete='off'
                  placeholder='e.g. SAP'
                  error={error?.message}
                  {...field}
                />
              )}
            />
          ))}
          <IconButton
            className='mt-1'
            variant='white'
            icon={<PlusIcon />}
            onClick={() => appendInTitle({ value: '' })}
          />
        </div>

        {Object.entries(uiFieldNameInputs).map(([fieldName, inputValues]) => (
          <div key={fieldName}>
            <label className='block text-sm font-medium text-gray-700'>
              Keyword(s) to look from the {fieldName}
            </label>
            {inputValues.map((fieldValue, index) => (
              <InputField
                key={`${fieldName}.${index}`}
                size='s'
                value={fieldValue}
                onChange={(e) =>
                  onChangeUiFieldKeywordValue(e.currentTarget.value, fieldName, index)
                }
                autoComplete='off'
                placeholder='e.g. Invoice'
              />
            ))}
            <IconButton
              className='mt-1'
              variant='white'
              icon={<PlusIcon />}
              onClick={() => onAddUiFieldInput(fieldName)}
            />
          </div>
        ))}
      </div>

      {isForProcessConfigs && (
        <div className='mt-5'>
          <Text size='l' weight={500} className='mt-2'>
            Process Identifier Collection
          </Text>
          <div className='mt-1 space-y-3'>
            <Controller
              name='processName'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputField
                  size='xs'
                  label='Process Or Dimension Name'
                  autoComplete='off'
                  placeholder='e.g. SAP Purchase Invoice Or Invoice Status'
                  error={error?.message}
                  {...field}
                />
              )}
            />

            <div className='space-y-1'>
              <Controller
                name='processIdPathBefore'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputField
                    size='xs'
                    label='URL path before process ID'
                    autoComplete='off'
                    placeholder='e.g. case/'
                    error={error?.message}
                    {...field}
                  />
                )}
              />
              <Link size='s' onClick={() => setIsIdFromPathHelpOpen(true)}>
                Show Instructions
              </Link>
            </div>

            <div className='space-y-1'>
              <Controller
                name='processIdParamKey'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputField
                    size='xs'
                    label='URL query parameter for process ID'
                    autoComplete='off'
                    placeholder='e.g. invoice_id'
                    error={error?.message}
                    {...field}
                  />
                )}
              />
              <Link size='s' onClick={() => setIsIdFromQueryHelpOpen(true)}>
                Show Instructions
              </Link>
            </div>

            <div className='space-y-1'>
              <Controller
                name='processIdTitleBefore'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputField
                    size='xs'
                    label='Title keyword before process ID'
                    autoComplete='off'
                    placeholder='e.g. Case'
                    error={error?.message}
                    {...field}
                  />
                )}
              />
              <Link size='s' onClick={() => setIsIdFromTitleHelpOpen(true)}>
                Show Instructions
              </Link>
            </div>
          </div>
        </div>
      )}

      <IdentifierFromUrlPathHelpModal
        open={isIdFromPathHelpOpen}
        onClose={() => setIsIdFromPathHelpOpen(false)}
      />

      <IdentifierFromUrlQueryHelpModal
        open={isIdFromQueryHelpOpen}
        onClose={() => setIsIdFromQueryHelpOpen(false)}
      />

      <IdentifierFromTitleHelpModal
        open={isIdFromTitleHelpOpen}
        onClose={() => setIsIdFromTitleHelpOpen(false)}
      />
    </div>
  )
}

export default DataCollectionRuleForm
