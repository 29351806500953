import { useState } from 'react'

import { generateRuleIn } from '../../common/components/ApplicationConfigPage/dataHelpers'
import {
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'
import TrainingDataCreateNewWindowOrAppForm from './TrainingDataCreateNewWindowForm'
import TrainingDataMatchedRowsModal from './TrainingDataMatchedRowsModal'
import { DEFAULT_GRANULARITY_DATA } from './constants'

interface Props {
  appBaseRule: DataCollectionRuleFormData
  appName: string
  isSystemDiscoveryApp: boolean
  onSaveNewRuleAndTestCase: (
    testCase: DataCollectionTestCaseFormData | null,
    ruleDraft: RuleIn | null,
  ) => void
}

const TrainingDataCreateNewRule = ({
  appBaseRule,
  appName,
  isSystemDiscoveryApp,
  onSaveNewRuleAndTestCase,
}: Props) => {
  const [ruleDraft, setRuleDraft] = useState<RuleIn | null>(null)

  const onCreateDraftRule = (formData: DataCollectionRuleFormData, windowOrAppName: string) => {
    const dashboardContext = isSystemDiscoveryApp
      ? { app_name: windowOrAppName }
      : {
          app_name: appName,
          window_name: windowOrAppName,
        }

    setRuleDraft(generateRuleIn(formData, DEFAULT_GRANULARITY_DATA, dashboardContext))
  }

  const onSubmitRule = (testCase: DataCollectionTestCaseFormData | null) => {
    onSaveNewRuleAndTestCase(testCase, ruleDraft)
  }

  return (
    <>
      <TrainingDataCreateNewWindowOrAppForm
        isSystemDiscoveryApp={isSystemDiscoveryApp}
        appBaseRule={appBaseRule}
        onCreateDraftRule={onCreateDraftRule}
      />

      <TrainingDataMatchedRowsModal
        isOpen={ruleDraft !== null}
        ruleDraft={ruleDraft}
        appName={appName}
        onClose={() => setRuleDraft(null)}
        onSubmit={onSubmitRule}
      />
    </>
  )
}

export default TrainingDataCreateNewRule
