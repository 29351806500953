import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'

import { useGetTrainingDataQuery } from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { IconButton, Table, Text } from '../../common/designs'
import { getTrainingDataTableValues } from './TrainingDataLogic'
import type { TrainingDataFormValues, TrainingDataTableValues } from './types'

const RESULTS_PER_PAGE = 100

interface Props {
  appName: string
  isSystemDiscoveryApp: boolean
  formSelections: TrainingDataFormValues
  fetchDataAgainTrigger: number
}

const TrainingDataList = ({
  appName,
  isSystemDiscoveryApp,
  formSelections,
  fetchDataAgainTrigger,
}: Props) => {
  const [visualizedData, setVisualizedData] = useState<TrainingDataTableValues>({
    tableHeaders: [],
    tableRows: [],
  })
  const [activeDataPageIndex, setActiveDataPageIndex] = useState<number>(0)
  const [isLoadingTableValues, setIsLoadingTableValues] = useState<boolean>(false)

  const {
    data,
    isLoading: isLoadingServerData,
    refetch,
  } = useGetTrainingDataQuery(
    {
      application_name: appName,
      is_system_discovery_app: isSystemDiscoveryApp,
    },
    {},
  )

  useEffect(() => {
    refetch()
  }, [fetchDataAgainTrigger, refetch])

  useEffect(() => {
    setIsLoadingTableValues(true)
    data && setVisualizedData(getTrainingDataTableValues(formSelections, data))
    setActiveDataPageIndex(0)
    setIsLoadingTableValues(false)
  }, [data, formSelections])

  if (isLoadingServerData || isLoadingTableValues) return <Loader />

  const hasPreviousPage = activeDataPageIndex !== 0
  const hasNextPage =
    visualizedData.tableRows.length > RESULTS_PER_PAGE + activeDataPageIndex * RESULTS_PER_PAGE
  const currentPageSliceStart = activeDataPageIndex * RESULTS_PER_PAGE
  const currentPageSliceEnd = currentPageSliceStart + RESULTS_PER_PAGE

  return (
    <div>
      <Table
        label='Training Data'
        head={visualizedData.tableHeaders}
        body={visualizedData.tableRows.slice(currentPageSliceStart, currentPageSliceEnd)}
      />

      <div className='flex gap-1 pl-2'>
        <IconButton
          size='xs'
          variant='ghost'
          icon={<ArrowLeftCircleIcon />}
          disabled={!hasPreviousPage}
        />
        <Text size='xs' className='mt-1.5 text-gray-300'>
          {currentPageSliceStart + 1} -{' '}
          {Math.min(currentPageSliceEnd, visualizedData.tableRows.length)} (of{' '}
          {visualizedData.tableRows.length})
        </Text>
        <IconButton
          size='xs'
          variant='ghost'
          icon={<ArrowRightCircleIcon />}
          disabled={!hasNextPage}
        />
      </div>
    </div>
  )
}

export default TrainingDataList
