import { useCallback, useState } from 'react'

import { Modal, Tabs } from '../../../../../common/designs'
import Analysis from './Analysis'
import StateMetrics from './StateMetrics'
import StepUserActivities from './StepUserActivities'
import { stateMetricNameExtraDataMapper } from './analysisConfigs'
import { ActiveTabEnum } from './constants'

const navTabs = [
  { label: 'State Metrics', value: ActiveTabEnum.STEP_METRICS },
  { label: 'State Analysis', value: ActiveTabEnum.TASK_ANALYSIS },
  { label: 'User Activities', value: ActiveTabEnum.USER_ACTIVITIES },
]

interface Props {
  isOpen: boolean
  onClose: () => void
  stateName: string
}

export const StateDataModal = ({ isOpen, onClose, stateName }: Props) => {
  const [activeViewValue, setActiveViewValue] = useState<ActiveTabEnum>(ActiveTabEnum.STEP_METRICS)
  const onTabSelected = useCallback((val: string) => setActiveViewValue(val as ActiveTabEnum), [])

  let activeViewComponent = null

  if (isOpen) {
    switch (activeViewValue) {
      case ActiveTabEnum.STEP_METRICS:
        activeViewComponent = <StateMetrics />
        break
      case ActiveTabEnum.TASK_ANALYSIS:
        activeViewComponent = <Analysis metricOptionMapper={stateMetricNameExtraDataMapper} />
        break
      case ActiveTabEnum.USER_ACTIVITIES:
        activeViewComponent = <StepUserActivities />
        break
      default:
        activeViewComponent = <StateMetrics />
        break
    }
  }

  return (
    <Modal label={stateName} open={isOpen} onClose={onClose} size='6xl'>
      <Tabs size='s' options={navTabs} value={activeViewValue} onChange={onTabSelected} />
      <div className='size-full'>{activeViewComponent}</div>
    </Modal>
  )
}

export default StateDataModal
