import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  useCreateDataCollectionsConfigApplicationMutation,
  useGetDataCollectionConfigsQuery,
} from '../../app/apiSlice'
import { Loader } from '../../common/components'
import { generateConfigTestCase } from '../../common/components/ApplicationConfigPage/dataHelpers'
import { generateFormDataFromRule } from '../ConfigureDataCollectionAdvancedAdminPanelPage/configInputTransformations'
import type {
  DataCollectionRuleFormData,
  DataCollectionTestCaseFormData,
  RuleIn,
} from '../ConfigureDataCollectionAdvancedAdminPanelPage/types'
import TrainingDataCreateNewRule from './TrainingDataCreateNewRule'
import TrainingDataForm from './TrainingDataForm'
import TrainingDataList from './TrainingDataList'
import { DEFAULT_GRANULARITY_DATA } from './constants'
import type { TrainingDataFormValues } from './types'
import useManageServerTestCasesAndConfigs from './useManageServerTestCasesAndConfigs'

const DEFAULT_FORM_VALUES = {
  isProcessNameValueUsed: true,
  isUrlValueUsed: true,
  isTitleValueUsed: true,
  removeUrlIdentifierAfterKeyWord: '',
  cutUrlAfterKeyword: '',
  removeTitleIdentifierAfterKeyWord: '',
  cutTitleAfterKeyword: '',
}

const SYSTEM_DISCOVERY_APP_NAME = 'System Discovery'

const TrainingDataStudio = () => {
  const { encodedAppName = '' } = useParams()
  const { createTestCases, createRules, isUploadingTestCases, isUploadingRules } =
    useManageServerTestCasesAndConfigs()
  const { data: ruleData, isLoading: isLoadingAppRules } = useGetDataCollectionConfigsQuery()
  const [createAppCommonInfo, { isLoading: isLoadingAppCommonInfoCreation }] =
    useCreateDataCollectionsConfigApplicationMutation()

  const [appBaseRuleFormData, setAppBaseRuleFormData] = useState<DataCollectionRuleFormData | null>(
    null,
  )
  const [appBaseRule, setAppBaseRule] = useState<RuleIn | null>(null)
  const [formSelections, setFormSelections] = useState<TrainingDataFormValues>(DEFAULT_FORM_VALUES)
  const [fetchDataAgainTrigger, setFetchDataAgainTrigger] = useState<number>(0)

  const isSystemDiscoveryApp = encodedAppName === SYSTEM_DISCOVERY_APP_NAME

  useEffect(() => {
    if (!encodedAppName || !ruleData) return

    const dataForThisApp = ruleData.rules.find(
      (rule) => rule.dashboard_context?.app_name === encodedAppName,
    )

    setAppBaseRule(dataForThisApp ?? null)

    if (!dataForThisApp)
      return setAppBaseRuleFormData({ inUrl: [{ value: '' }], inTitle: [{ value: '' }] })

    const { standardFormValues } = generateFormDataFromRule(dataForThisApp, [])
    setAppBaseRuleFormData(standardFormValues)
  }, [encodedAppName, ruleData])

  const onAddTestCaseForWindow = async (
    formValues: DataCollectionTestCaseFormData,
    windowName: string,
  ) => {
    const newTestCase = generateConfigTestCase(formValues, DEFAULT_GRANULARITY_DATA, {
      app_name: encodedAppName,
      window_name: windowName,
    })
    newTestCase.expected_salvage_fields =
      appBaseRule?.salvage_fields ?? newTestCase.expected_salvage_fields
    await createTestCases([newTestCase])
  }

  const onAddTestCaseForApp = async (
    formValues: DataCollectionTestCaseFormData,
    appName: string,
  ) => {
    const newTestCase = generateConfigTestCase(formValues, DEFAULT_GRANULARITY_DATA, {
      app_name: appName,
    })
    await createTestCases([newTestCase])
  }

  const onSaveNewRuleAndTestCase = (
    testCase: DataCollectionTestCaseFormData | null,
    ruleDraft: RuleIn | null,
  ) => {
    if (!ruleDraft) return

    createRules([ruleDraft])

    if (!testCase) return

    if (isSystemDiscoveryApp && ruleDraft.dashboard_context?.app_name) {
      onAddTestCaseForApp(testCase, ruleDraft.dashboard_context.app_name)
      createAppCommonInfo({
        name: ruleDraft.dashboard_context.app_name,
        category: 'Other',
        is_pdf_application: false,
      })
    } else if (ruleDraft.dashboard_context?.window_name) {
      onAddTestCaseForWindow(testCase, ruleDraft.dashboard_context.window_name)
    }

    setFetchDataAgainTrigger(fetchDataAgainTrigger + 1)
  }

  if (
    isUploadingTestCases ||
    isUploadingRules ||
    isLoadingAppRules ||
    isLoadingAppCommonInfoCreation ||
    !appBaseRuleFormData
  )
    return <Loader />

  return (
    <div className='gap-y-2'>
      <TrainingDataCreateNewRule
        appBaseRule={appBaseRuleFormData}
        appName={encodedAppName}
        isSystemDiscoveryApp={isSystemDiscoveryApp}
        onSaveNewRuleAndTestCase={onSaveNewRuleAndTestCase}
      />

      <TrainingDataForm onFormSend={setFormSelections} defaultValues={DEFAULT_FORM_VALUES} />

      <TrainingDataList
        appName={encodedAppName}
        isSystemDiscoveryApp={isSystemDiscoveryApp}
        formSelections={formSelections}
        fetchDataAgainTrigger={fetchDataAgainTrigger}
      />
    </div>
  )
}

export default TrainingDataStudio
